import React, { useState, useEffect } from 'react';
import '../../libs/firebase';
import * as FirestoreService from '../../libs/firestore';
import useQueryString from '../../hooks/useQueryString'

import StartOver from '../StartOver';
import Winner from '../Winner';
import Join from '../Join';
import Share from '../Share';
import UpNext from '../UpNext';
import './style.css';

const YELP_URL = "https://us-central1-forage-together.cloudfunctions.net/yelp"

function App() {
  const [pairingSession, setPairingSession] = useState({});
  const [error, setError] = useState();
  const [approvedList, setApprovedList] = useState([]);
  const [rejectedList, setRejectedList] = useState([]);
  const [pairingSessionId, setPairingSessionId] = useQueryString('pair');
  const [restaurantList, setRestaurantList] = useState([]);

  // Authenticate user
  useEffect(() => {
    FirestoreService
      .authenticateAnonymously()
      .catch(() => setError('anonymous-auth-failed'));
  }, []);


  // Find current pairing session
  useEffect(() => {
    if (pairingSessionId) {
      return;
    }

    FirestoreService.currentPairingSession()
      .then(async (querySnapshot) => {
        const docs = querySnapshot.docs || []
        if (docs.length) {
          setPairingSessionId(docs[0].id);
          return;
        }

        if (pairingSessionId) {
          return;
        }

        FirestoreService
          .createPairingSession()
          .then((doc) => {
            setPairingSessionId(doc.id);
          });
      });
  }, [pairingSessionId, setPairingSessionId]);

  useEffect(() => {
    FirestoreService.setPairingSessionId(pairingSessionId);
  }, [pairingSessionId]);

  // Load or create pairing session
  useEffect(() => {
    if (!pairingSessionId) {
      return;
    }

    const unsubscribe = FirestoreService.streamPairingSession({
      next: snapshot => {
        const data = Object.assign({ id: pairingSessionId }, snapshot.data());
        setPairingSession(data)
      },
      error: () => {
        setError('session-get-fail');
        setPairingSession({});
      }
    });
    return unsubscribe;
  }, [pairingSessionId]);

  // Stream approved list
  useEffect(() => {
    if (pairingSessionId) {
      const unsubscribe = FirestoreService.streamApprovedList({
        next: snapshot => {
          const updatedApprovedList =
            snapshot.docs.map(docs => docs.data());
          setApprovedList(updatedApprovedList);
        },
        error: reason => {
          if (reason.message === 'Missing or insufficient permissions.') {
            setPairingSession({});
          } else {
            setError('approved-list-get-fail');
            // setPairingSessionId();
          }
        }
      });
      return unsubscribe;
    }
  }, [pairingSessionId, setApprovedList]);

  // Stream rejected list
  useEffect(() => {
    if (pairingSessionId) {
      const unsubscribe = FirestoreService.streamRejectedList({
        next: snapshot => {
          const updatedRejectedList =
            snapshot.docs.map(docs => docs.data());
          setRejectedList(updatedRejectedList);
        },
        error: reason => {
          if (reason.message === 'Missing or insufficient permissions.') {
            setPairingSession({});
          } else {
            setError('rejected-list-get-fail');
            // setPairingSessionId();
          }
        }
      });
      return unsubscribe;
    }
  }, [pairingSessionId, setRejectedList]);

  // Query Yelp
  useEffect(() => {
    if (pairingSession.location) {
      const data = Object.assign({
        // TODO: Pagination?
      }, pairingSession.location)

      fetch(YELP_URL, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(body => body.json())
        .then(resp => {
          const existing = restaurantList.map(r => r.id)
          return resp.businesses.filter(r => !existing.includes(r.id)).sort(() => Math.random() - 0.5)
        })
        .then(businesses => {
          const list = restaurantList.concat(businesses)
          setRestaurantList(list);
        })
        .catch(err => {
          console.error("Yelp error", err);
        })
    }
    // eslint-disable-next-line
  }, [pairingSession]);

  const userId = FirestoreService.getUserId();
  const rejectedIds = rejectedList.map(r => r.yelpId);
  const recommendations = approvedList.filter(r => !rejectedIds.includes(r.yelpId) && r.yelpId !== pairingSession.winner);

  Join({ session: pairingSession });

  const reset = (doc) => {
    setPairingSessionId(doc.id);
    setPairingSession({});
    setRestaurantList([]);
  }

  return (
    <div className="App">
      <header className="App-header">
        {error && <div>Error: {error}</div>}
        <StartOver then={reset} />

        <Winner winner={restaurantList.find(r => r.alias === pairingSession.winner)} recommendations={recommendations} />
        {!userId && (<div>Finding a few places to eat...</div>)}

        {/* TODO: join button */}

        <UpNext restaurants={restaurantList} approved={approvedList} rejected={rejectedList} />
        <Share session={pairingSession} />
      </header>
    </div>
  );
}

export default App;
