import React from 'react';
import RestaurantCard from '../RestaurantCard';
import './style.scss';

function Winner({ winner, recommendations }) {

  if (!winner) {
    return null;
  }

  return (
    <div className="Winner">
      <h1>You're going to: {winner.name}!</h1>
      <RestaurantCard restaurant={winner} />

      <div className="Winner__Recommendations">
        Also consider:
        {recommendations.map((restaurant, i) => (
        <div key={i}>
          {restaurant.yelpId}
        </div>

      ))}

      </div>
    </div>
  );
}

export default Winner;
