import React from 'react';
import RestaurantCard from '../RestaurantCard';
import { addApprovedListItem, addRejectedListItem } from '../../libs/firestore';
import './style.scss';

function UpNext({ restaurants, rejected, approved }) {
  const approvedIds = approved.map(r => r.yelpId);
  const rejectedIds = rejected.map(r => r.yelpId);

  const upNext = [];

  restaurants.forEach(r => {
    if (!rejectedIds.includes(r.alias) && !approvedIds.includes(r.alias)) {
      upNext.push(r);
    }

    if (upNext.length > 2) {
      // TODO: short circuit foreach
    }
  });

  const current = upNext[0];

  const approveItem = () => {
    if (current) {
      addApprovedListItem(current.alias)
        .catch(reason => {
          console.log("error", reason)
          // setError('item-add-error');
        });
    }
  }

  const rejectItem = () => {
    if (current) {
      addRejectedListItem(current.alias)
        .catch(reason => {
          console.log("error", reason)
          // setError('item-reject-error');
        });
    }
  }

  return (
    <>
      <div className="UpNext">
        {upNext.map((r, idx) => (
          <RestaurantCard key={r.id} restaurant={r} index={idx} />
        ))}
      </div>

      <div className="Actions">
        <div className="Button" onClick={rejectItem}><span role="img" aria-label="Reject">🗑</span></div>
        <div className="Button" onClick={approveItem}><span role="img" aria-label="Approve">🍲</span></div>
      </div>
    </>
  );
}

export default UpNext;
