export const people = [
  "😄",
  "😁",
  "😆",
  "😅",
  "🤣",
  "😂",
  "😊",
  "😇",
  "🥰",
  "😍",
  "🤩",
  "😘",
  "😗",
  "😚",
  "😙",
  "😋",
  "😛",
  "🤗",
  "🤫",
  "🤔",
  "🤠",
  "🥳",
  "😎",
  "🤓",
  "🧐",
  "👻",
  "👽",
  "👾",
  "🤖",
  "😺",
  "😸",
  "😹",
  "😻",
  "😽",
  "👉",
  "👍",
  "👏",
  "🙌",
  "🙏",
  "💅",
  "👀",
  "👁",
  "👶",
  "🧒",
  "👦",
  "👧",
  "🧑",
  "👱",
  "👨",
  "🧔",
  "👨‍🦰",
  "👨‍🦱",
  "👨‍🦳",
  "👨‍🦲",
  "👩",
  "👩‍🦰",
  "🧑‍🦰",
  "👩‍🦱",
  "🧑‍🦱",
  "👩‍🦳",
  "🧑‍🦳",
  "👩‍🦲",
  "🧑‍🦲",
  "👱‍♀️",
  "👱‍♂️",
  "🧓",
  "👴",
  "👵",
  "🙍",
  "🙍‍♂️",
  "🙍‍♀️",
  "🙎",
  "🙎‍♂️",
  "🙎‍♀️",
  "🙅",
  "🙅‍♂️",
  "🙅‍♀️",
  "🙆",
  "🙆‍♂️",
  "🙆‍♀️",
  "💁",
  "💁‍♂️",
  "💁‍♀️",
  "🙋",
  "🙋‍♂️",
  "🙋‍♀️",
  "🧏",
  "🧏‍♂️",
  "🧏‍♀️",
  "🙇",
  "🙇‍♂️",
  "🙇‍♀️",
  "🤦",
  "🤦‍♂️",
  "🤦‍♀️",
  "🤷",
  "🤷‍♂️",
  "🤷‍♀️",
  "🧑‍⚕️",
  "👨‍⚕️",
  "👩‍⚕️",
  "🧑‍🎓",
  "👨‍🎓",
  "👩‍🎓",
  "🧑‍🏫",
  "👨‍🏫",
  "👩‍🏫",
  "🧑‍⚖️",
  "👨‍⚖️",
  "👩‍⚖️",
  "🧑‍🌾",
  "👨‍🌾",
  "👩‍🌾",
  "🧑‍🍳",
  "👨‍🍳",
  "👩‍🍳",
  "🧑‍🔧",
  "👨‍🔧",
  "👩‍🔧",
  "🧑‍🏭",
  "👨‍🏭",
  "👩‍🏭",
  "🧑‍💼",
  "👨‍💼",
  "👩‍💼",
  "🧑‍🔬",
  "👨‍🔬",
  "👩‍🔬",
  "🧑‍💻",
  "👨‍💻",
  "👩‍💻",
  "🧑‍🎤",
  "👨‍🎤",
  "👩‍🎤",
  "🧑‍🎨",
  "👨‍🎨",
  "👩‍🎨",
  "🧑‍✈️",
  "👨‍✈️",
  "👩‍✈️",
  "🧑‍🚀",
  "👨‍🚀",
  "👩‍🚀",
  "🧑‍🚒",
  "👨‍🚒",
  "👩‍🚒",
  "👮",
  "👮‍♂️",
  "👮‍♀️",
  "🕵",
  "🕵️‍♂️",
  "🕵️‍♀️",
  "💂",
  "💂‍♂️",
  "💂‍♀️",
  "👷",
  "👷‍♂️",
  "👷‍♀️",
  "🤴",
  "👸",
  "👳",
  "👳‍♂️",
  "👳‍♀️",
  "👲",
  "🧕",
  "🤵",
  "👰",
  "🤰",
  "🤱",
  "🧑‍🍼",
  "👼",
  "🎅",
  "🤶",
  "🦸",
  "🦸‍♂️",
  "🦸‍♀️",
  "🦹",
  "🦹‍♂️",
  "🦹‍♀️",
  "🧙",
  "🧙‍♂️",
  "🧙‍♀️",
  "🧚",
  "🧚‍♂️",
  "🧚‍♀️",
  "🧛",
  "🧛‍♂️",
  "🧛‍♀️",
  "🧜",
  "🧜‍♂️",
  "🧜‍♀️",
  "🧝",
  "🧝‍♂️",
  "🧝‍♀️",
  "🧞",
  "🧞‍♂️",
  "🧞‍♀️",
  "💃",
  "🕺",
  "🕴",
  "👯",
  "👯‍♂️",
  "👯‍♀️",
  "🧑‍🤝‍🧑",
  "👭",
  "👫",
  "👬",
  "💏",
  "👨‍❤️‍💋‍👨",
  "👩‍❤️‍💋‍👩",
  "💑",
  "👨‍❤️‍👨",
  "👩‍❤️‍👩",
  "👪",
  "👨‍👩‍👦",
  "👨‍👩‍👧",
  "👨‍👨‍👦",
  "👨‍👨‍👧",
  "👩‍👩‍👦",
  "👩‍👩‍👧",
  "👨‍👦",
  "👨‍👦‍👦",
  "👨‍👧",
  "👨‍👧‍👦",
  "👨‍👧‍👧",
  "👩‍👦",
  "👩‍👦‍👦",
  "👩‍👧",
  "👩‍👧‍👦",
  "👩‍👧‍👧",
]

export const things = [
  "🍈",
  "🍉",
  "🍊",
  "🍋",
  "🍍",
  "🥭",
  "🍎",
  "🍏",
  "🍐",
  "🍒",
  "🍓",
  "🥝",
  "🍅",
  "🥥",
  "🥑",
  "🥔",
  "🥕",
  "🌽",
  "🌶",
  "🥒",
  "🥬",
  "🥦",
  "🧄",
  "🧅",
  "🍄",
  "🥜",
  "🌰",
  "🍞",
  "🥐",
  "🥨",
  "🥯",
  "🥞",
  "🧇",
  "🧀",
  "🍖",
  "🍗",
  "🥩",
  "🥓",
  "🍔",
  "🍟",
  "🍕",
  "🌭",
  "🥪",
  "🌮",
  "🌯",
  "🥙",
  "🧆",
  "🍳",
  "🥘",
  "🍲",
  "🥣",
  "🥗",
  "🍿",
  "🧈",
  "🧂",
  "🥫",
  "🍱",
  "🍘",
  "🍙",
  "🍚",
  "🍛",
  "🍜",
  "🍝",
  "🍠",
  "🍢",
  "🍣",
  "🍤",
  "🍥",
  "🥮",
  "🍡",
  "🥟",
  "🥠",
  "🥡",
  "🦪",
  "🍦",
  "🍧",
  "🍨",
  "🍩",
  "🍪",
  "🎂",
  "🍰",
  "🧁",
  "🥧",
  "🍫",
  "🍬",
  "🍭",
  "🍮",
  "🍯",
  "🍼",
  "🥛",
  "☕",
  "🍵",
  "🍶",
  "🍾",
  "🍷",
  "🍸",
  "🍹",
  "🍺",
  "🍻",
  "🥂",
  "🥃",
  "🥤",
  "🧃",
  "🧉",
  "🧊",
  "🥢",
  "🍽",
  "🍴",
  "🥄",
  "⛱",
  "🧨",
  "🎈",
  "🎉",
  "🎊",
  "🎎",
  "🎏",
  "🎐",
  "🧧",
  "🎀",
  "🎁",
  "🔥",
]

export const RandomPair = () => {
  const person = people[Math.floor(Math.random() * people.length)];
  const thing = things[Math.floor(Math.random() * things.length)];

  return `${person}${thing}`;
}

export const RandomList = () => {
  const person = people[Math.floor(Math.random() * people.length)];
  const thing = () => things[Math.floor(Math.random() * things.length)];

  return `${person}${thing()}${thing()}${thing()}`;
}
