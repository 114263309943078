import React, { useState } from 'react';
import './style.scss';

function Share({ session }) {
  const shareSheet = (e) => {
    if (navigator.share) {
      e.preventDefault();
      navigator.share({ title: '🥡', url: window.location.href })
    }
  }

  return (
    <>
      <div className="Share Button" onClick={shareSheet}>
        <span role="img" aria-label="Share">
          📨
        </span>
      </div>
    </>
  );
}

export default Share;
