import React from 'react';
import { createPairingSession } from '../../libs/firestore';
import './style.scss';

function StartOver({ then }) {
  const reset = () => {
    createPairingSession()
      .then(then);
  }

  return (
    <div className="StartOver Button" onClick={reset}>
      <span role="img" aria-label="Start Over">
        🔄
      </span>
    </div>
  );
}

export default StartOver;
