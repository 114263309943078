import React, { useState, useEffect } from 'react';
import Hammer from 'react-hammerjs';
import { addApprovedListItem, addRejectedListItem } from '../../libs/firestore';
import classNames from 'classnames';
import './style.scss';

const TotalCards = 5;

function RestaurantCard({ restaurant, index }) {
  const [moving, setMoving] = useState(false);
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [transform, setTransform] = useState("");

  var styles = {
    zIndex: TotalCards - index,
    opacity: (TotalCards - index) / TotalCards,
    transform,
  };

  useEffect(() => {
    setTransform(`scale(${(20 - index) / 20}) translateY(-${30 * index}px)`);
  }, [index])

  const onPan = (event) => {
    setMoving(true);

    if (event.deltaX === 0) return;
    if (event.center.x === 0 && event.center.y === 0) return;

    setApproved(event.deltaX > 0);
    setRejected(event.deltaX < 0);

    var xMulti = event.deltaX * 0.03;
    var yMulti = event.deltaY / 80;
    var rotate = xMulti * yMulti;

    setTransform(`translate(${event.deltaX}px, ${event.deltaY}px) rotate(${rotate}deg)`)
  }

  const onPanEnd = (event) => {
    setMoving(false)

    const moveOutWidth = document.body.clientWidth;
    const keep = Math.abs(event.deltaX) < 80 || Math.abs(event.velocityX) < 0.5;

    if (keep) {
      setApproved(false);
      setRejected(false);
      setTransform("");
    } else {
      if (approved) {
        approveItem()
      } else {
        rejectItem();
      }

      var endX = Math.max(Math.abs(event.velocityX) * moveOutWidth, moveOutWidth);
      var toX = event.deltaX > 0 ? endX : -endX;
      var endY = Math.abs(event.velocityY) * moveOutWidth;
      var toY = event.deltaY > 0 ? endY : -endY;
      var xMulti = event.deltaX * 0.03;
      var yMulti = event.deltaY / 80;
      var rotate = xMulti * yMulti;

      setTransform(`translate(${toX}px, ${(toY + event.deltaY)}px) rotate(${rotate}deg)`)
    }
  }

  const approveItem = () => {
    addApprovedListItem(restaurant.alias)
      .catch(reason => {
        console.log("error", reason)
        // setError('item-add-error');
      });
  }

  const rejectItem = () => {
    addRejectedListItem(restaurant.alias)
      .catch(reason => {
        console.log("error", reason)
        // setError('item-reject-error');
      });
  }

  if (!restaurant) {
    return null;
  }

  return (
    <Hammer onPan={onPan} onPanEnd={onPanEnd}>
      <div
        className={classNames("RestaurantCard", {
          "RestaurantCard--moving": moving,
          "RestaurantCard--rejected": rejected,
          "RestaurantCard--approved": approved,
        })}
        style={styles}
      >
        <div className="RestaurantCard__Image" style={{ backgroundImage: `url(${restaurant.image_url})` }} />
        {/* <img src={restaurant.image_url} alt={restaurant.name} />
        </div> */}
        <div className="RestaurantCard__Info">
          <h2>{restaurant.name}</h2>

          <div className="RestaurantCard__Rating">{restaurant.rating} stars</div>
          <a className="RestaurantCard__Phone" href={`tel:restaurant.phone`}>{restaurant.phone}</a>
        </div>
      </div>
    </Hammer>
  );
}

export default RestaurantCard;
