import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { RandomList } from './emoji';

const SESSIONS = 'pairings'
const db = firebase.firestore();

const Session = () => db.collection(SESSIONS).doc(PairingSessionId);
const ApprovedList = () => Session().collection('approved');
const RejectedList = () => Session().collection('rejected');

var PairingSessionId = "";
var UserId = "";

export const setPairingSessionId = (update) => PairingSessionId = update
export const setUserId = (update) => UserId = update
export const getUserId = () => UserId

export const authenticateAnonymously = () => {
  return firebase.auth()
    .signInAnonymously()
    .then(credentials => setUserId(credentials.user.uid));
};

export const createPairingSession = async () => {
  const name = RandomList();
  const location = await fetch('https://freegeoip.app/json/')
    .then(response => response.json())
    .then(({ latitude, longitude }) => ({ latitude, longitude }));

  return db.collection(SESSIONS)
    .add({
      name,
      location,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: UserId,
      users: [UserId]
    });
};

export const currentPairingSession = () => {
  return db.collection(SESSIONS)
    .where("users", "array-contains", UserId)
    .orderBy("created")
    .limit(1)
    .get();
};

export const streamPairingSession = (observer) => {
  return Session().onSnapshot(observer);
};

// export const updateUserName = (name) => {
//   return Session()
//     .update({
//       [`names.${UserId}`]: name
//     });
// };

export const addUserToPairingSession = () => {
  firebase.analytics().logEvent("pair_user")

  return Session()
    .update({
      users: firebase.firestore.FieldValue.arrayUnion(UserId)
    });
};

export const getApprovedList = () => {
  return ApprovedList().get();
}

export const streamApprovedList = (observer) => {
  return ApprovedList()
    .orderBy('created')
    .onSnapshot(observer);
};

export const addApprovedListItem = (yelpId) => {
  firebase.analytics().logEvent("approve_restaurant")

  return getApprovedList()
    .then(querySnapshot => querySnapshot.docs)
    .then(items => items.find(item => item.data().yelpId.toLowerCase() === yelpId.toLowerCase()))
    .then(matchingItem => {
      if (matchingItem) {
        return ApprovedList()
          .doc(matchingItem.id)
          .update({
            users: firebase.firestore.FieldValue.arrayUnion(UserId)
          })
          .then(() => checkForWinner(matchingItem.id));
      } else {
        return ApprovedList()
          .add({
            yelpId,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            createdBy: UserId,
            users: [UserId]
          });
      }
    });
};

export const checkForWinner = (choiceId) => {
  ApprovedList()
    .doc(choiceId)
    .get()
    .then(item => {
      const choice = item.data();
      if (choice.users < 2) {
        return;
      }

      const choiceUsers = choice.users.sort().join('-');
      Session()
        .get()
        .then(session => {
          const sessionUsers = session.data().users.sort().join('-');

          if (choiceUsers === sessionUsers) {
            Session()
              .update({
                winner: choice.yelpId
              });
          }
        });
    });
};

export const getRejectedList = () => {
  return RejectedList().get();
}

export const streamRejectedList = (observer) => {
  return RejectedList()
    .orderBy('created')
    .onSnapshot(observer);
};

export const addRejectedListItem = (yelpId) => {
  firebase.analytics().logEvent("reject_restaurant")

  return RejectedList()
    .add({
      yelpId,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: UserId
    });
};
