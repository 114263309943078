import { getUserId, addUserToPairingSession } from '../../libs/firestore';

function Join({ session }) {
  if (
    !session ||
    !session.users ||
    session.users.includes(getUserId())
  ) {
    return;
  }

  addUserToPairingSession();
}

export default Join;
