import * as Firebase from "firebase/app";
import "firebase/analytics";

export const firebase = Firebase.initializeApp({
  apiKey: "AIzaSyCK0W8Ls8ZC8Z5kf3v_PFQZPyiArEPD17o",
  authDomain: "forage-together.firebaseapp.com",
  databaseURL: "https://forage-together.firebaseio.com",
  projectId: "forage-together",
  storageBucket: "forage-together.appspot.com",
  messagingSenderId: "319295868591",
  appId: "1:319295868591:web:0e99d7966021711b4fce5a",
  measurementId: "G-1H20EYFBWS"
});

export const analytics = Firebase.analytics();
